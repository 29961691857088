import React from 'react';
import { Field, CccisdSelect } from 'cccisd-formik';
import { client } from 'cccisd-apollo';
import query from './districtList.graphql';

const categories = window.cccisd.appDefs.pawn.fields[0].values;
const list = categories.map(category => ({ value: category.value, label: category.name }));
const empty = { value: '', label: '-- Select --' };
list.unshift(empty);

export default class Component extends React.Component {
    state = { district: [] };

    componentDidMount() {
        const getDistricts = async () => {
            const response = await client.query({
                query,
                variables: {},
            });
            const districtLists = response.data.groups.siteList.map(district => ({
                value: district.group.groupId,
                label: district.group.label,
                schoolList: district.childGroups.schoolList.map(school => ({
                    value: school.group.groupId.toString(),
                    label: school.group.label,
                })),
            }));
            districtLists.unshift(empty);
            this.setState({ district: districtLists });
        };
        getDistricts();
    }

    render() {
        const schools =
            this.state.district.find(d => d.value.toString() === this.props.values.initialDistrict)?.schoolList || [];

        return (
            <>
                <Field
                    name="initialDistrict"
                    component={CccisdSelect}
                    label="District:"
                    options={this.state.district}
                    onChange={e => {
                        this.props.setFieldValue('initialDistrict', e.target.value);
                        this.props.setFieldValue('schoolGroupId', '');
                    }}
                />

                <Field name="schoolGroupId" component={CccisdSelect} label="School:" options={[empty, ...schools]} />

                <Field
                    name="traineeCategory"
                    component={CccisdSelect}
                    label="Which best describes your role?"
                    options={list}
                />
            </>
        );
    }
}
