import React from 'react';

const SchoolName = ({ value, row, loadData, settings }) => {
    if (row['fields.schoolGroupId']) {
        const schools = row['ancestorGroups.site.childGroups.schoolList'];
        const selectedSchool = schools.find(s => s.group.groupId === row['fields.schoolGroupId']) || null;
        return selectedSchool?.group?.label || '';
    }
    return '';
};

export default (settings, callbackHandler) => {
    return props => <SchoolName {...props} settings={settings} />;
};
