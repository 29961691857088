import React from 'react';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import { useLocation, Redirect } from 'cccisd-react-router';
import District from '../District';
import style from './style.css';

const Register = () => {
    const { pathname, search } = useLocation();
    if (!search && pathname.startsWith('/licensing/voucher/redeem')) {
        return <Redirect to={pathname + `?redirectUrl=${pathname}`} />;
    }

    const registerLoginProps = {
        registerProps: {
            role: 'learner',
            showPasswordFields: true,
            title: 'Register',
            validateAdditionalFields: values => {
                let errors = {};
                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                if (!values.initialDistrict) {
                    errors.initialDistrict = 'District is required.';
                }
                if (!values.schoolGroupId) {
                    errors.schoolGroupId = 'School is required.';
                }
                if (!values.traineeCategory) {
                    errors.traineeCategory = 'Role is required.';
                }
                return errors;
            },
            renderAdditionalFields: props => {
                return <District values={props.values} setFieldValue={props.setFieldValue} />;
            },
        },
        loginProps: { title: 'Login' },
    };

    return (
        <div className={style.registerPage}>
            <RegisterLoginPage {...registerLoginProps} />
        </div>
    );
};

export default Register;
